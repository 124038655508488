import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SignIn from "layouts/authentication/sign-in";
import { useAuth } from "layouts/authentication/AuthContext";
import PrivateRoute from "layouts/authentication/PrivateRoute";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Get user and loading state from AuthContext
  const { user, loading } = useAuth(); // ✅ Fetch user and loading state from AuthContext safely

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return route.protected ? (
          <Route path="/" key={route.key} element={<PrivateRoute />}>
            <Route exact path={route.route} element={route.component} />
          </Route>
        ) : (
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }
      return null;
    });

  // Conditionally render the configsButton if user is defined and has "Admin" permissions
  const configsButton =
    user && user.permissions === "Admin" ? (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="#ace396"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="1rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
      >
        <DefaultNavbarLink name="" light={false} icon="add" route="/sprays/createspray" />
      </MDBox>
    ) : null;

  // If loading, render a loading message or spinner
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or custom loading screen
  }

  return (
    <ThemeProvider
      theme={
        // eslint-disable-next-line no-nested-ternary
        direction === "rtl" ? (darkMode ? themeDarkRTL : themeRTL) : darkMode ? themeDark : theme
      }
    >
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="45 South Orchard"
            routes={routes}
            onMouseEnter={() => {
              if (miniSidenav && !onMouseEnter) {
                setMiniSidenav(dispatch, false);
                setOnMouseEnter(true);
              }
            }}
            onMouseLeave={() => {
              if (onMouseEnter) {
                setMiniSidenav(dispatch, true);
                setOnMouseEnter(false);
              }
            }}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="/authentication/sign-in" element={<SignIn />} />
      </Routes>
    </ThemeProvider>
  );
}
