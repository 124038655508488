/* eslint-disable no-unused-vars */
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import PieChart from "examples/Charts/PieChart";

// Dashboard components
import RunData from "layouts/dashboard/components/RunData";
import PackoutOverview from "layouts/dashboard/components/PackoutOverview";
import SelectedData from "layouts/dashboard/components/SelectedData";

// // React
import { useEffect, useState } from "react";

// QC App Data
import { qcdb, qcauth } from "firebaseDb";
import {
  getDocs,
  collectionGroup,
  // collection,
  // where,
  // query,
  // setDoc,
  // doc,
} from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { CSVLink } from "react-csv";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    border: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

function Dashboard() {
  const [data, setData] = useState([]);
  const [loadingdata, setLoading] = useState(true);

  // Average Data
  const [averages, setAverages] = useState({ cull: 0, dom: 0, export: 0, varieties: [] });

  // Currently Selected Row
  const [row, setRow] = useState({ index: 0, variety: "", orchard: "", date: "" });

  useEffect(() => {
    signInWithEmailAndPassword(qcauth, "test@45s.co.nz", "1p2o3i4u5y6t")
      .then(() => {
        const reportsQuery = collectionGroup(qcdb, "redpearlcsv");
        const tempdata = [];
        setLoading(true);
        getDocs(reportsQuery).then((querySnapshot) => {
          querySnapshot.forEach((docu) => {
            const exp = docu.data().datasets ? docu.data().datasets[0].data[2] : 0;
            tempdata.push({
              ...docu.data(),
              key: docu.id,
              completion: (
                <MDBox width="8rem" textAlign="left">
                  <MDProgress
                    value={exp}
                    // eslint-disable-next-line no-nested-ternary
                    color={exp > 65 ? "success" : exp > 55 ? "warning" : "error"}
                    variant="gradient"
                    label
                  />
                </MDBox>
              ),
            });
          });
          const sortedrows = tempdata.sort((a, b) =>
            b.date.localeCompare(a.date, undefined, { numeric: true, sensitivity: "base" })
          );
          setData(sortedrows);
          const threeDaysAgo = new Date(sortedrows[0].date);
          threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
          const filteredData = sortedrows.filter((r) => new Date(r.date) >= threeDaysAgo);
          const tempvarieties = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < filteredData.length; i++) {
            if (!tempvarieties.includes(filteredData[i].variety)) {
              tempvarieties.push(filteredData[i].variety);
            }
          }
          const totalCull = filteredData.reduce(
            (accumulator, currentValue) => accumulator + currentValue.datasets[0].data[0],
            0
          );
          const totalDom = filteredData.reduce(
            (accumulator, currentValue) => accumulator + currentValue.datasets[0].data[1],
            0
          );
          const totalEx = filteredData.reduce(
            (accumulator, currentValue) => accumulator + currentValue.datasets[0].data[2],
            0
          );
          setAverages({
            cull: Math.round(totalCull / filteredData.length),
            dom: Math.round(totalDom / filteredData.length),
            export: Math.round(totalEx / filteredData.length),
            varieties: tempvarieties,
          });
          setRow({
            index: 0,
            variety: sortedrows[0].variety,
            orchard: sortedrows[0].orchard,
            date: sortedrows[0].date,
          });
          setLoading(false);
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const headers = [
    { label: "Orchard", key: "orchard" },
    { label: "Variety", key: "variety" },
    { label: "Date", key: "date" },
    { label: "22mm", key: "size22mm" },
    { label: "24mm", key: "size24mm" },
    { label: "26mm", key: "size26mm" },
    { label: "28mm", key: "size28mm" },
    { label: "30mm", key: "size30mm" },
    { label: "32mm", key: "size32mm" },
    { label: "34mm", key: "size34mm" },
    { label: "36mm", key: "size36mm" },
  ];

  const csvData = data.map((csvrow) => ({
    orchard: csvrow.orchard,
    variety: csvrow.variety,
    date: csvrow.date,
    size22mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[0] : 0,
    size24mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[1] : 0,
    size26mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[2] : 0,
    size28mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[3] : 0,
    size30mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[4] : 0,
    size32mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[5] : 0,
    size34mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[6] : 0,
    size36mm: csvrow.chartSizeData.series ? csvrow.chartSizeData.series[0].data[7] : 0,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                {!loadingdata && (
                  <ReportsBarChart
                    color="info"
                    title="Size Information"
                    date="campaign sent 2 days ago"
                    chart={{
                      labels: ["22mm", "24mm", "26mm", "28mm", "30mm", "32mm", "34mm", "36mm"],
                      datasets: {
                        label: "Percentage",
                        data: data[row.index].chartSizeData.series
                          ? data[row.index].chartSizeData.series[0].data
                          : [0, 0, 0, 0, 0, 0, 0, 0],
                        hoverOffset: 4,
                      },
                    }}
                  />
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                {!loadingdata && (
                  <PieChart
                    color="success"
                    title="Quality Information"
                    height="10.5rem"
                    chart={
                      data[row.index].datasets
                        ? data[row.index]
                        : {
                            datasets: [{ backgroundColor: ["#BC4749", "#fcba03", "#6A994E"] }],
                            data: [100, 0, 0],
                            labels: ["Cull", "Domestic", "Export"],
                          }
                    }
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox sx={{ textAlign: "center" }}> {loadingdata && <CircularProgress />}</MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {!loadingdata && <SelectedData info={data} setRow={setRow} row={row} />}
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              {!loadingdata && <RunData info={data} setRow={setRow} selectedrow={row} />}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {!loadingdata && <PackoutOverview averages={averages} />}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox sx={{ textAlign: "center", mt: 3 }}>
          {!loadingdata && (
            <CSVLink data={csvData} headers={headers} filename="sortedrows.csv">
              <button type="button">Download CSV</button>
            </CSVLink>
          )}
        </MDBox>
      </MDBox>
      {!loadingdata && <Footer />}
    </DashboardLayout>
  );
}

export default Dashboard;
