import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress"; // Import spinner

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { auth } from "firebaseDb";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "layouts/authentication/AuthContext"; // Import AuthContext

function Basic() {
  const { loading, fetchUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [localLoading, setLocalLoading] = useState(false); // Track button loading

  const navigate = useNavigate();

  async function handleSignIn() {
    setLocalLoading(true); // Start button loading
    setError(null);

    try {
      const credentials = await signInWithEmailAndPassword(auth, email, password);

      // Fetch Firestore user data
      await fetchUser(credentials.user);

      // Wait for Firestore data to be ready before navigating
      if (!loading) {
        navigate("/");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLocalLoading(false); // Stop button loading
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            {error && (
              <MDTypography
                textAlign="center"
                variant="body1"
                fontWeight="medium"
                color="error"
                mt={1}
              >
                {error}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="success"
                fullWidth
                onClick={() => handleSignIn()}
                disabled={localLoading} // Disable button when loading
              >
                {localLoading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
