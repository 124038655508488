import { createContext, useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { auth, db } from "firebaseDb";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchUser(firebaseUser) {
    if (!firebaseUser) {
      setUser(null);
      setLoading(false);
      return;
    }
    try {
      const userDocRef = doc(db, "Users", firebaseUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUser({ uid: firebaseUser.uid, ...userDoc.data() });
      } else {
        console.error("No user document found for UID:", firebaseUser.uid);
        setUser(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      fetchUser(firebaseUser);
    });
    return unsubscribe;
  }, []);

  const contextValue = useMemo(() => ({ user, loading, fetchUser }), [user, loading]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
