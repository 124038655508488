// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack"; // Import Stack from Material-UI

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React
import { useState } from "react";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "firebaseDb";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function SprayInfoCard({ title, info, shadow, setSprayInfo, sprayInfo }) {
  const labels = [];
  const values = [];
  const [loading, setLoading] = useState(false);

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => {
    if (label === "date") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <DatePicker
                label="SPRAY DATE"
                value={values[key].seconds ? new Date(values[key].seconds * 1000) : values[key]}
                onChange={(newDate) => {
                  setSprayInfo((prevState) => ({
                    ...prevState,
                    sprayDate: newDate ? newDate.$d : "", // Set to empty string if newDate is null
                  }));
                  setLoading(true);
                  const sprayRef = doc(db, "Sprays", sprayInfo.key);
                  setDoc(
                    sprayRef,
                    {
                      ...sprayInfo,
                      sprayDate: newDate ? newDate.$d : "", // Save empty string if cleared
                      status: newDate ? "Sprayed" : "Ready to Spray",
                    },
                    { merge: true }
                  ).then(() => {
                    setLoading(false);
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {loading && <CircularProgress color="success" size={24} />}
            </Stack>
          </LocalizationProvider>
        </MDBox>
      );
    }
    return (
      <MDBox key={label} display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text" sx={{ fontSize: "1rem" }}>
          &nbsp;{values[key]}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#b2d6bb" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
SprayInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props
SprayInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default SprayInfoCard;
