import { Outlet, Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import { useAuth } from "./AuthContext";

function PrivateRoute() {
  const { user, loading } = useAuth(); // Use `loading` from AuthContext

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  return user ? <Outlet /> : <Navigate to="/authentication/sign-in" replace />;
}

export default PrivateRoute;
